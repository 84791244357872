import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../utils/PageWrapper";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import {
  Typography,
  Grid,
  IconButton,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  TextField,
  Button,
  Tooltip,
  MenuItem,
} from "@material-ui/core";
import {
  Loading,
  ErrorMessage,
} from "@web-applications/daffodil-component-library";
import { openSnackbar } from "../../../reusable/Notifier";
import { format } from "date-fns";
import { Field, Form } from "react-final-form";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  emailValidation,
  GET_ADDITIONAL_RECIPIENTS,
  labelColour,
  projectStatuses,
} from "../../../queries/Project";
import { openDialog } from "../../../reusable/ScrollablePopup";
import ProjectScopeModal from "../ProjectScopeModal";
import ProjectDetailsModal from "../ProjectDetailsModal";
import { GET_UPDATES } from "../../../queries/Project";
import { CREATE_UPDATE, ProjectUpdate } from "./ProjectUpdates";
import { primaryColor } from "../../../assets/ThemeStyling";
import SmallFormPopover from "../../../reusable/SmallFormPopover";
import { statusLabel } from "../../../assets/GlobalLabels";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import MomentUtils from "@date-io/moment";
import AsynchronousAutocomplete from "../../../uicomponents/Autocomplete";
import ProjectMatterportLinksTable from "../ProjectMatterportLinksTable";

const ourLabelColour = "#BB4430";
const ourLabelStyle = { color: ourLabelColour };

const TextFieldAdapter = ({ input, meta, ...rest }) => {
  const useStyles = makeStyles(() => ({
    noBorder: {
      border: "none",
    },
  }));
  const classes = useStyles();
  return (
    <TextField
      {...input}
      {...rest}
      onChange={({ target: { value } }) => input.onChange(value)}
      errorText={meta.touched ? meta.error : ""}
      variant="outlined"
      color="secondary"
      fullWidth
      classes={{ notchedOutline: classes.input }}
      InputProps={{
        classes: { notchedOutline: classes.noBorder },
      }}
    />
  );
};

function ProjectSummaryProjectDetails({ project, updateProject }) {
  return (
    <Card style={{ marginBottom: "24px", padding: "0" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            padding: "18px 24px",
            // borderBottom: "1px solid #E7EAEF",
            backgroundColor: "#4B4F54",
          }}
        >
          <Typography
            variant="h5"
            style={{
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            Project Details
            <Tooltip title="Edit" placement="top">
              <IconButton
                onClick={() => {
                  openDialog({
                    content: (
                      <ProjectDetailsModal
                        project={project}
                        onSaveClicked={(newProject) => {
                          updateProject({
                            variables: {
                              ...newProject,
                              address_2: newProject.address_2 || "",
                              _id: project._id,
                            },
                          });
                        }}
                      />
                    ),
                  });
                }}
              >
                {/* <MoreVert style={{ fill: primaryColor }} /> */}
                <CreateIcon style={{ fill: primaryColor }} />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} style={{ padding: "24px" }}>
          <div style={{ marginBottom: "15px" }}>
            <Typography variant="body2" style={ourLabelStyle} gutterBottom>
              Status
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SmallFormPopover
                arrowIcon
                initialValues={{
                  project_status_text: project.project_status_text,
                }}
                onSubmit={(values) => {
                  updateProject({
                    variables: {
                      project_status_text: values.project_status_text,
                      _id: project._id,
                    },
                  });
                }}
                content={
                  <Field name="project_status_text">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        select
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                        label={statusLabel}
                        fullWidth
                        required
                        variant="outlined"
                      >
                        {projectStatuses.map((role) => (
                          <MenuItem key={role} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                }
              />
              <Typography variant="body1">
                {project.project_status_text
                  ? project.project_status_text
                  : "Not set"}
              </Typography>
            </div>
          </div>

          <div style={{ marginBottom: "15px" }}>
            <Typography variant="body2" style={ourLabelStyle} gutterBottom>
              Start on Site Date
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SmallFormPopover
                eventIcon
                noDateButton
                initialValues={{
                  start_on_site: project.start_on_site,
                }}
                onSubmit={(values) => {
                  updateProject({
                    variables: {
                      start_on_site: values.start_on_site,
                      _id: project._id,
                    },
                  });
                }}
                content={
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Field name="start_on_site">
                      {({ input, meta }) => (
                        <DatePicker
                          variant="static"
                          disableToolbar={true}
                          format="DD/MM/YYYY"
                          name={input.name}
                          onChange={input.onChange}
                          value={input.value ? input.value : null}
                          onBlur={input.onBlur}
                          fullWidth
                          required={false}
                          clearable={true}
                          disablePast={false}
                          autoOk={true}
                          disabled={false}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : ""
                          }
                          label="Start on Site"
                          leftArrowIcon={<KeyboardArrowLeft />}
                          inputVariant="outlined"
                          rightArrowIcon={<KeyboardArrowRight />}
                        />
                      )}
                    </Field>
                  </MuiPickersUtilsProvider>
                }
              />
              <Typography variant="body1">
                {project.start_on_site
                  ? format(new Date(project.start_on_site), "dd/MM/yyyy")
                  : "Not set"}
              </Typography>
            </div>
          </div>

          <div style={{ marginBottom: "15px" }}>
            <Typography variant="body2" style={ourLabelStyle} gutterBottom>
              Handover Date
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SmallFormPopover
                eventIcon
                noDateButton
                content={
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Field name="est_handover_date">
                      {({ input, meta }) => (
                        <DatePicker
                          variant="static"
                          disableToolbar
                          format="DD/MM/YYYY"
                          name={input.name}
                          onChange={input.onChange}
                          value={input.value ? input.value : null}
                          onBlur={input.onBlur}
                          fullWidth
                          required={false}
                          clearable={true}
                          disablePast={false}
                          autoOk={true}
                          disabled={false}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : ""
                          }
                          label="Handover Date"
                          leftArrowIcon={<KeyboardArrowLeft />}
                          inputVariant="outlined"
                          rightArrowIcon={<KeyboardArrowRight />}
                        />
                      )}
                    </Field>
                  </MuiPickersUtilsProvider>
                }
                onSubmit={(values) => {
                  updateProject({
                    variables: {
                      est_handover_date: values.est_handover_date,
                      _id: project._id,
                    },
                  });
                }}
                initialValues={{
                  est_handover_date: project.est_handover_date,
                }}
              />
              <Typography variant="body1">
                {project.est_handover_date
                  ? format(new Date(project.est_handover_date), "dd/MM/yyyy")
                  : "Not set"}
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid item md={6} style={{ padding: "24px" }}>
          <div style={{ marginBottom: "15px" }}>
            <Typography
              variant="body2"
              style={{ color: labelColour }}
              gutterBottom
            >
              Client
            </Typography>
            <Typography variant="body1">
              {project.companies_name ? project.companies_name : "Not set"}
            </Typography>
          </div>

          <div style={{ marginBottom: "15px" }}>
            <Typography
              variant="body2"
              style={{ color: labelColour }}
              gutterBottom
            >
              Address
            </Typography>
            <Typography variant="body1">
              {project.address_1 ? project.address_1 : "Not set"}
            </Typography>
            <Typography variant="body1">
              {project.address_2 && project.address_2}
            </Typography>
            <Typography variant="body1">
              {project.city ? project.city : "Not set"}
            </Typography>
            <Typography variant="body1">
              {project.postcode ? project.postcode : "Not set"}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

function ProjectSummaryScopeOfWorks({ project, projectUsers, updateProject }) {
  const clientDirect = () => (
    <Typography variant="body1">Client Direct</Typography>
  );
  const userFilter = (role) => {
    let filteredUsers = projectUsers.filter(
      (user) => user.role === role && user.lead,
    );

    return filteredUsers[0] ? (
      filteredUsers.map((user) => (
        <Typography variant="body1">{`${user.first_name} ${user.last_name}`}</Typography>
      ))
    ) : (
      <Typography variant="body1">Info Required</Typography>
    );
  };
  return (
    <Card style={{ marginBottom: "24px", padding: "0" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            padding: "18px 24px",
            // borderBottom: "1px solid #E7EAEF",
            backgroundColor: "#4B4F54",
          }}
        >
          <Typography
            variant="h5"
            style={{
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            Project Scope
            <Tooltip title="Edit" placement="top">
              <IconButton
                // onClick={(event) => {
                //   setAnchorEl(event.currentTarget);
                // }}
                onClick={() => {
                  // setAnchorEl2(null);
                  openDialog({
                    content: (
                      <ProjectScopeModal
                        project={project}
                        onSaveClicked={(newProject) => {
                          updateProject({
                            variables: {
                              ...newProject,
                              _id: project._id,
                            },
                          });
                        }}
                      />
                    ),
                  });
                }}
              >
                <CreateIcon style={{ fill: primaryColor }} />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} style={{ padding: "24px" }}>
          <div style={{ marginBottom: "15px" }}>
            <Typography
              variant="body2"
              style={{ color: labelColour }}
              gutterBottom
            >
              Design
            </Typography>

            {project.services_design ? userFilter("Design") : clientDirect()}
          </div>

          <div style={{ marginBottom: "15px" }}>
            <Typography
              variant="body2"
              style={{ color: labelColour }}
              gutterBottom
            >
              Project Manager
            </Typography>

            {project.services_build
              ? userFilter("Project Manager")
              : clientDirect()}
          </div>
        </Grid>

        <Grid item xs={12} md={4} style={{ padding: "24px" }}>
          <div style={{ marginBottom: "15px" }}>
            <Typography
              variant="body2"
              style={{ color: labelColour }}
              gutterBottom
            >
              Project QS
            </Typography>
            {project.services_qs ? userFilter("QS") : clientDirect()}
          </div>

          <div style={{ marginBottom: "15px" }}>
            <Typography
              variant="body2"
              style={{ color: labelColour }}
              gutterBottom
            >
              Health &amp; Safety
            </Typography>

            {project.services_hs
              ? userFilter("Health And Safety")
              : clientDirect()}
          </div>
        </Grid>

        <Grid item xs={12} md={4} style={{ padding: "24px" }}>
          <div style={{ marginBottom: "15px" }}>
            <Typography
              variant="body2"
              style={{ color: labelColour }}
              gutterBottom
            >
              F&amp;F
            </Typography>

            {project.services_ff ? userFilter("Furniture") : clientDirect()}
            <Typography variant="body1"></Typography>
          </div>
        </Grid>

        <Grid item md={12} style={{ padding: "24px" }}>
          <div style={{ marginBottom: "15px" }}>
            <Typography
              variant="body2"
              style={{ color: labelColour }}
              gutterBottom
            >
              Scope of Works
            </Typography>
            <Typography
              variant="body1"
              style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
            >
              {project.scope_of_works ? project.scope_of_works : "Not set"}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

function ProjectSummaryMatterportLinks({ project }) {
  return <ProjectMatterportLinksTable project={project} />;
}

function ProjectSummaryUpdates({ project }) {
  const user = useContext(UserContext);
  const [expanded, setExpanded] = useState(false);
  const {
    loading: updatesLoading,
    error: updatesError,
    data: updatesData,
  } = useQuery(GET_UPDATES, {
    variables: {
      project_job_no: project.job_no,
    },
  });

  const [
    createUpdate,
    {
      loading: createUpdateLoading,
      error: createUpdateError,
      data: createUpdateData,
    },
  ] = useMutation(CREATE_UPDATE, {
    refetchQueries: [
      {
        query: GET_UPDATES,
        variables: {
          project_job_no: project.job_no,
        },
      },
      {
        query: GET_ADDITIONAL_RECIPIENTS,
      },
    ],
    onError: () => {
      openSnackbar({
        message: "Error adding update, please try again",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "Update added successfully",
        type: "success",
      });
      setExpanded(false);
    },
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (updatesError) {
    return <ErrorMessage message={updatesError} />;
  }

  if (updatesLoading || createUpdateLoading) {
    return <Loading />;
  }

  return (
    <Card style={{ padding: "0" }}>
      <Form
        onSubmit={(values, form) => {
          createUpdate({
            variables: {
              project_job_no: project.job_no,
              project_mongo_id: project._id,
              the_update: values.the_update,
              user_id: user.user_id,
              recipients: values.recipients
                ? values.recipients
                    .filter((recipient) => recipient._id)
                    .map((recipient) => recipient.emailAddress)
                : [],
              newRecipients: values.recipients
                ? values.recipients.filter(
                    (recipient) => typeof recipient === "string",
                  )
                : [],
            },
          });
          form.reset();
        }}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          form: {
            mutators: { push, pop },
          },
          submitting,
          pristine,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Accordion
              style={{
                marginBottom: "24px",
                borderRadius: "0",
              }}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                style={{
                  padding: "4px 24px 4px 10px",
                  backgroundColor: "#4B4F54",
                  height: "84px",
                }}
                expandIcon={
                  expanded === "panel1" ? (
                    <Tooltip title="Close" placement="top">
                      <CloseIcon style={{ fill: primaryColor }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add" placement="top">
                      <AddCircleIcon style={{ fill: primaryColor }} />
                    </Tooltip>
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    margin: "12px 12px 8px 12px",
                    fontWeight: "600",
                    color: "white",
                  }}
                >
                  Project Updates
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12} style={{ marginTop: "12px" }}>
                    <Field
                      name="the_update"
                      component={TextFieldAdapter}
                      multiline
                      minRows={3}
                      required
                      label="Type your update here..."
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FieldArray name="recipients">
                      {({ fields }) =>
                        fields.map((recipient, index) => (
                          <div
                            key={recipient}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "1rem",
                            }}
                          >
                            <Field
                              name={`${recipient}`}
                              component={AsynchronousAutocomplete}
                              validate={emailValidation}
                              label="Recipient Email"
                              placeholder="Email"
                            />

                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => fields.remove(index)}
                              type="button"
                              size="small"
                              style={{
                                marginLeft: "1rem",
                                maxHeight: "40px",
                              }}
                            >
                              Remove
                            </Button>
                          </div>
                        ))
                      }
                    </FieldArray>
                  </Grid>

                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={() => push("recipients", undefined)}
                      >
                        Add Recipient
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={pristine || submitting}
                      >
                        Post
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </form>
        )}
      />

      {updatesData.getProjectUpdates.length > 0 ? (
        <>
          {[...updatesData.getProjectUpdates]
            .reverse()
            .map((update, i, { length }) => {
              if (i < 10) {
                return (
                  <ProjectUpdate
                    key={update._id}
                    update={update}
                    length={length}
                    i={i}
                  />
                );
              }
            })}
        </>
      ) : (
        <Typography
          variant="body2"
          style={{ color: labelColour, margin: "24px" }}
          gutterBottom
        >
          No updates.
        </Typography>
      )}
    </Card>
  );
}

export default function ProjectSummary({
  project,
  projectUsers,
  updateProject,
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <ProjectSummaryProjectDetails
          project={project}
          updateProject={updateProject}
        />
        <ProjectSummaryScopeOfWorks
          project={project}
          projectUsers={projectUsers}
          updateProject={updateProject}
        />
        <ProjectSummaryMatterportLinks project={project} />
      </Grid>
      <Grid item xs={12} md={4}>
        {project.job_no === null ? (
          <Loading />
        ) : (
          <ProjectSummaryUpdates project={project} />
        )}
      </Grid>
    </Grid>
  );
}
